import validate from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/pages/runtime/validate.js";
import cleanup_45scrollock_45global from "/home/runner/work/Storefront/Storefront/middleware/cleanupScrollock.global.ts";
import update_45after_45login_45path_45global from "/home/runner/work/Storefront/Storefront/middleware/updateAfterLoginPath.global.js";
import update_45points_45available_45global from "/home/runner/work/Storefront/Storefront/middleware/updatePointsAvailable.global.ts";
import manifest_45route_45rule from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cleanup_45scrollock_45global,
  update_45after_45login_45path_45global,
  update_45points_45available_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "cs-auth": () => import("/home/runner/work/Storefront/Storefront/middleware/csAuth.ts")
}