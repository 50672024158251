export default defineNuxtPlugin(({ hook }) => {
  hook("app:mounted", () => {
    if ("fonts" in document) {
      const fontSans = new FontFace(
        "cs-inter",
        'url("/fonts/inter/western-subset/inter-regular.woff2") format("woff2")',
        { style: "normal" },
      )

      const fontSansIt = new FontFace(
        "cs-inter",
        'url("/fonts/inter/western-subset/inter-italic.woff2") format("woff2")',
        { style: "oblique" },
      )

      const fontSansBold = new FontFace(
        "cs-inter",
        'url("/fonts/inter/western-subset/inter-semibold.woff2") format("woff2")',
        { style: "normal", weight: "bold" },
      )

      const fontSansBoldIt = new FontFace(
        "cs-inter",
        'url("/fonts/inter/western-subset/inter-semibolditalic.woff2") format("woff2")',
        { style: "oblique", weight: "bold" },
      )

      const fontBrand = new FontFace(
        "cs-poppins",
        'url("/fonts/poppins/western-subset/poppins-regular.woff2") format("woff2")',
        { style: "normal" },
      )

      const fontBrandBold = new FontFace(
        "cs-poppins",
        'url("/fonts/poppins/western-subset/poppins-semibold.woff2") format("woff2")',
        { style: "normal", weight: "bold" },
      )

      const fontSerif = new FontFace(
        "cs-klinic",
        'url("/fonts/klinic/western-subset/klinicslab-book.woff2") format("woff2"), url("/fonts/klinic/western-subset/klinicslab-book.woff") format("woff")',
        { style: "normal" },
      )

      const fontSerifIt = new FontFace(
        "cs-klinic",
        'url("/fonts/klinic/western-subset/klinicslab-bookit.woff2") format("woff2"), url("/fonts/klinic/western-subset/klinicslab-bookit.woff") format("woff")',
        { style: "oblique" },
      )

      Promise.all([
        fontSans.load(),
        fontSansIt.load(),
        fontSansBold.load(),
        fontSansBoldIt.load(),
        fontBrand.load(),
        fontBrandBold.load(),
        fontSerif.load(),
        fontSerifIt.load(),
      ]).then(function (loadedFonts) {
        loadedFonts.forEach(function (font) {
          document.fonts.add(font)
        })
      })
    }
  })
})
