import { deserialize } from "@alchemy_cms/json_api"

export const useRewardStore = defineStore("rewards", () => {
  const { api } = useApi()
  const authStore = useAuthStore()
  const userDetail: Ref<UserDetail | null> = ref(null)

  const pointsAvailable = ref(0)

  const setPointsAvailable = (DetailPayload: UserDetail) => {
    userDetail.value = DetailPayload

    if (DetailPayload) {
      pointsAvailable.value = DetailPayload.pointsAvailable
    }
  }

  const getUserDetails = async (id: string) => {
    if (id) {
      try {
        const response = await api<any>(`/jsonapi/user_details/${id}`)
        setPointsAvailable(deserialize(response))
      } catch (error) {
        console.error(error)
        authStore.resetState()
        resetState()
      }
    }
  }

  const resetState = () => {
    userDetail.value = null
    pointsAvailable.value = 0
  }

  return {
    userDetail,
    setPointsAvailable,
    getUserDetails,
    resetState,
    pointsAvailable,
  }
})
