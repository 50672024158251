// Remove wistia json+ld tags from the head so they
// do not stay in the head on subsequent page visits
// See https://github.com/CandleScience/Storefront/issues/1180
export default defineNuxtPlugin(({ hook }) => {
  hook("app:mounted", () => {
    const router = useRouter()
    router.afterEach(() => {
      document.head
        .querySelectorAll(".w-json-ld")
        .forEach((node) => node.remove())
    })
  })
})
