import { createStore } from "vuex"
import nav from "~/store/nav.js"
import tsfByState from "~/store/tsfByState.js"

export default defineNuxtPlugin((nuxtApp) => {
  const store = createStore({
    modules: {
      nav,
      tsfByState,
    },
  })
  nuxtApp.vueApp.use(store)
  return {
    provide: {
      store,
    },
  }
})
