export const state = () => ({
  mobileNavIsOpen: false,
  selectedItem: null,
  selectedRow: null,
})

export const mutations = {
  setNavigationState(state, newState) {
    const body = document.querySelector("body")
    if (newState) {
      body.classList.add("scroll-block")
    } else {
      body.classList.remove("scroll-block")
    }
    state.selectedItem = null

    state.mobileNavIsOpen = newState
  },
  closeNavigation(state) {
    state.mobileNavIsOpen = false
    state.selectedItem = null
  },
  setNavItem(state, selectedItem) {
    state.selectedItem = selectedItem
  },
}

export const actions = {
  updateNavigationState({ commit }, newState) {
    commit("setNavigationState", newState)
    if (newState === false) {
      commit("setNavItem", null)
    }
  },
  resetNav({ commit }) {
    commit("setNavigationState", false)
    commit("closeNavigation")
    commit("setNavItem", null)
  },
  selectNavItem({ commit }, navItemId) {
    commit("setNavItem", navItemId)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
