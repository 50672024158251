import { useMediaQuery } from "@vueuse/core"

export default defineNuxtPlugin(() => {
  return {
    provide: {
      mq: {
        mini: useMediaQuery("(max-width: 659px)"),
        sm: useMediaQuery("(min-width: 660px) and (max-width: 959px)"),
        m: useMediaQuery("(min-width: 960px) and (max-width: 1049px)"),
        md: useMediaQuery("(min-width: 1050px) and (max-width: 1279px)"),
        lg: useMediaQuery("(min-width: 1280px)"),
      },
    },
  }
})
