import revive_payload_client_4sVQNw7RlN from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/Storefront/Storefront/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/Storefront/Storefront/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/Storefront/Storefront/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/Storefront/Storefront/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/home/runner/work/Storefront/Storefront/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import api_QQzOodE9HA from "/home/runner/work/Storefront/Storefront/plugins/api.js";
import gtm_cXsUZobmSF from "/home/runner/work/Storefront/Storefront/plugins/gtm.ts";
import vuex_owYp5qnaH8 from "/home/runner/work/Storefront/Storefront/plugins/vuex.js";
import fontloading_t6cQE0hL7F from "/home/runner/work/Storefront/Storefront/plugins/fontloading.js";
import csAuth_pbYNsmw7xH from "/home/runner/work/Storefront/Storefront/plugins/csAuth.js";
import wistia_client_c3FQufPjCH from "/home/runner/work/Storefront/Storefront/plugins/wistia.client.js";
import bugsnag_client_TtQ636hzGt from "/home/runner/work/Storefront/Storefront/plugins/bugsnag.client.ts";
import mediaQuery_WkAdzcH34z from "/home/runner/work/Storefront/Storefront/plugins/mediaQuery.ts";
import preview_2IjZF69ftJ from "/home/runner/work/Storefront/Storefront/plugins/preview.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  api_QQzOodE9HA,
  gtm_cXsUZobmSF,
  vuex_owYp5qnaH8,
  fontloading_t6cQE0hL7F,
  csAuth_pbYNsmw7xH,
  wistia_client_c3FQufPjCH,
  bugsnag_client_TtQ636hzGt,
  mediaQuery_WkAdzcH34z,
  preview_2IjZF69ftJ
]