import Bugsnag from "@bugsnag/js"
import BugsnagVue from "@bugsnag/plugin-vue"

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig()
  if (config.public.BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: config.public.BUGSNAG_API_KEY,
      enabledReleaseStages: ["production"],
      plugins: [new BugsnagVue()],
    })
    // I could not identify a way to correctly type this
    const bugsnagVue = Bugsnag.getPlugin("vue") as any
    vueApp.use(bugsnagVue)
  }
})
