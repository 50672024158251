<template>
  <component
    :is="buttonOrLink"
    :to="to || href"
    :tabindex="disabled && '-1'"
    class="whitespace-nowrap relative box-border font-sans cursor-pointer inline-flex items-center justify-center no-underline hover:no-underline min-w-0 disabled:opacity-45 disabled:pointer-events-none"
    :class="[BUTTON_CLASSES(), loading && 'button-loading']"
    :disabled="!(disabled || loading) ? undefined : true"
    @keydown="$emit('keydown', $event.key)"
    @blur="$emit('blur', $event)"
  >
    <div
      v-if="$slots.icon"
      class="mr-2 -ml-1 w-6 h-6 flex items-center"
      :class="size === 'small' && 'mr-2 w-5 h-5'"
    >
      <slot name="icon" />
    </div>
    <Spinner
      class="absolute"
      :spin="loading"
      :size="size === 'tiny' ? 'small' : size"
      :color="color == 'red' ? 'red' : color === 'gray' ? 'gray' : 'blue'"
    />
    <span :class="[loading && 'opacity-0']">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
  const NuxtLink = defineNuxtLink({})

  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: [String, Object],
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "large",
      validator: (value: string) => {
        return ["tiny", "small", "medium", "large"].includes(value)
      },
    },
    color: {
      type: String,
      default: "red",
      validator: (value: string) => {
        return [
          "red",
          "yellow",
          "gray",
          "blue",
          "green",
          "purple",
          "navy",
          "onyx",
        ].includes(value)
      },
    },
    layout: {
      type: String,
      default: "default",
      validator: (value: string) => {
        return ["default", "ghost", "inverted", "silent", "tint"].includes(
          value,
        )
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    roundedCorners: {
      type: Boolean,
      default: null,
    },
  })

  const BUTTON_CLASSES = () => {
    const classes = []

    classes.push(props.roundedCorners ? "rounded-full" : "rounded")

    // Size
    switch (props.size) {
      case "tiny":
        classes.push("h-6", "px-2", "text-sm")
        break
      case "small":
        if (props.roundedCorners === null) {
          classes.push("rounded-full")
        }
        classes.push("h-8", "px-3", "text-sm")
        break
      case "medium":
        classes.push("h-11", "px-4", "text-md")
        break
      case "large":
        classes.push("h-12", "px-5", "text-md")
        break
    }

    // Layout
    switch (props.layout) {
      case "default":
        classes.push("font-semibold", "border-none", "text-white")
        break
      case "inverted":
        classes.push(
          "font-bold",
          "border-none",
          "transition-[box-shadow]",
          "transition-duration-180",
          "hover:shadow",
        )
        break
      case "silent":
        classes.push(
          "font-bold",
          "border-none",
          "bg-white",
          "transition-[background-color]",
          "transition-duration-220",
        )
        break
      case "ghost":
        classes.push(
          "font-bold",
          "bg-transparent",
          "border",
          "border-solid",
          "transition-[background-color]",
          "transition-duration-220",
        )
        break
      case "tint":
        classes.push("border border-solid font-semibold")
        break
    }

    // Colors
    switch (props.color) {
      case "red":
        switch (props.layout) {
          case "default":
            classes.push("bg-red-600", "hover:bg-red-700")
            break
          case "inverted":
            classes.push("text-red-600", "bg-white", "hover:shadow-red-200")
            break
          case "ghost":
            classes.push("text-red-600", "border-red-200", "hover:bg-red-100")
            break
          case "silent":
            classes.push("text-red-text", "hover:bg-red-tint")
            break
          case "tint":
            classes.push(
              "border-red-200",
              "bg-red-tint",
              "text-red-text",
              "hover:bg-red-100",
            )
            break
        }
        break

      case "gray":
        switch (props.layout) {
          case "default":
            classes.push("bg-gray-text", "hover:bg-gray-800")
            break
          case "inverted":
            classes.push("text-onyx-500", "bg-white", "hover:shadow")
            break
          case "ghost":
            classes.push(
              "text-gray-600",
              "border-gray-500",
              "hover:bg-gray-100",
            )
            break
          case "silent":
            classes.push("text-gray-text", "hover:bg-gray-tint")
            break
          case "tint":
            classes.push(
              "border-gray-500",
              "bg-gray-tint",
              "text-gray-text",
              "hover:bg-gray-100",
            )
            break
        }
        break
      case "onyx":
        switch (props.layout) {
          case "default":
            classes.push("bg-onyx-500", "hover:bg-onyx-600")
            break
          case "ghost":
            classes.push("bg-gray-200 text-onyx-500", "hover:bg-gray-300")
            break
        }
        break
      default:
        switch (props.layout) {
          case "default":
            classes.push("bg-blue-text", "hover:bg-blue-700")
            break
          case "inverted":
            classes.push(
              "text-blue-600",
              "bg-white",
              "hover:shadow-blue-500/30",
            )
            break
          case "ghost":
            classes.push(
              "text-blue-600",
              "border-blue-500",
              "hover:bg-blue-100",
            )
            break
          case "silent":
            classes.push("text-blue-text", "hover:bg-blue-tint")
            break
          case "tint":
            classes.push(
              "border-blue-500",
              "bg-blue-tint",
              "text-blue-text",
              "hover:bg-blue-100",
            )
            break
        }
        break
    }

    return classes
  }

  const buttonOrLink = computed(() => {
    if (props.to || props.href) {
      return NuxtLink
    }
    return "button"
  })
</script>
