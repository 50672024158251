import { createGtm, useGtm, type GtmSupport } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin(({ vueApp, hook, $config }) => {
  let gtm: GtmSupport

  if (process.env.NODE_ENV === "test" || !$config.public.GTM_ID) {
    console.info("Using mocked $gtm for test environment.")
    gtm = {
      trackEvent(evt: any) {
        console.info("$gtm.trackEvent", evt)
      },
    } as GtmSupport
  } else {
    const gtmInstance = createGtm({
      id: $config.public.GTM_ID,
      source: "https://sgtm.candlescience.com/gtm.js",
      defer: true,
      compatibility: false,
      enabled: false,
      debug: $config.public.DEV,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
    })
    vueApp.use(gtmInstance)
    gtm = useGtm() as GtmSupport

    hook("app:mounted", (vueApp) => {
      vueApp.config.globalProperties.$gtm?.enable(!$config.public.DEV)
    })
  }

  return {
    provide: {
      csGtm: gtm,
    },
  }
})
