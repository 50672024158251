export default defineNuxtPlugin(() => {
  const route = useRoute()
  const alchemyPreviewMode = !!route.query.alchemy_preview_mode

  if (alchemyPreviewMode) {
    console.log("[Alchemy] Preview mode enabled.")
  }

  return {
    provide: {
      preview: alchemyPreviewMode,
    },
  }
})
