<template>
  <transition name="fade">
    <span v-show="spin" class="spinner" :class="cssClass" />
  </transition>
</template>

<script>
  export default {
    props: {
      spin: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: "medium",
        validator(value) {
          return ["small", "medium", "large"].includes(value)
        },
      },
      color: {
        type: String,
        default: "blue",
        validator(value) {
          return [
            "blue",
            "green",
            "yellow",
            "orange",
            "red",
            "purple",
            "navy",
            "onyx",
            "gray",
            "silver",
            "sand",
            "inherit",
          ].includes(value)
        },
      },
    },
    computed: {
      cssClass() {
        return [`spinner--${this.size}`, `spinner--${this.color}`]
      },
    },
  }
</script>

<style lang="scss">
  .spinner {
    display: inline-block;
    transform: translateZ(0);

    border-style: solid;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-width: calc($base-spacing / 2);
    border-radius: 50%;

    animation: rotate;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &--small {
      width: $base-spacing * 3;
      height: $base-spacing * 3;
    }

    &--medium {
      width: $base-spacing * 5;
      height: $base-spacing * 5;
    }

    &--large {
      width: $base-spacing * 7;
      height: $base-spacing * 7;
    }

    &--inherit {
      border-left-color: currentColor;
    }

    &--blue {
      border-left-color: $blue;
    }

    &--green {
      border-left-color: $green;
    }

    &--yellow {
      border-left-color: $yellow;
    }

    &--orange {
      border-left-color: $orange;
    }

    &--red {
      border-left-color: $red;
    }

    &--purple {
      border-left-color: $purple;
    }

    &--navy {
      border-left-color: $navy;
    }

    &--onyx {
      border-left-color: $onyx;
    }

    &--gray {
      border-left-color: $gray;
    }

    &--silver {
      border-left-color: $silver;
    }

    &--sand {
      border-left-color: $sand;
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition:
        opacity 300ms ease-in-out,
        visibility 300ms ease-in-out;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
