import Cookies from "js-cookie"
import { stringify } from "qs"
import type { FetchOptions } from "ofetch"

/**
 * Ofetch wrapper configured to use CSpree based on your API_URL
 * @example
 * const { api } = useApi()
 * const data = await api('/jsonapi/products')
 */
export default function useFetch() {
  const config = useRuntimeConfig()
  const baseUrl = ref(config.public.API_URL)

  const headers = {}

  const isProduction =
    config.public.BASE_URL === "https://www.candlescience.com"

  const credentials = import.meta.client ? "include" : undefined

  const apiFetch = $fetch.create({
    baseURL: baseUrl.value,
    credentials,
    headers,

    onRequest({ options }) {
      // We do lock away api.saynotoparmesan.com and other instances from the public so for staging reasons only we pass a client header secret to grant access to the api for the storefront
      if (!isProduction) {
        options.headers = {
          ...options.headers,
          "X-CS-Storefront-Client": config.public.CLIENT_HEADER_SECRET || "",
        }
      }

      // Requests with cookies cause OPTIONS request. AS we only rely on csrf cookies when user sensitive data is exchanged we only send the csrf token when the method is not GET
      if (options.method !== undefined && options.method !== "GET") {
        options.headers = {
          ...options.headers,
          "X-CSRF-Token": Cookies.get("CSRF-TOKEN") || "",
        }
      }
    },
  })

  const api = <Type>(
    path: string,
    options: FetchOptions = {},
  ): Promise<Type> => {
    const stringifiedParams = stringify(options?.params, {
      arrayFormat: "brackets",
      encode: false,
    })

    options.params = options?.params || {}
    const { params, ...rest } = options

    const url =
      Object.keys(options.params).length > 0
        ? `${path}?${stringifiedParams}`
        : path
    return apiFetch(url, rest as any)
  }

  return {
    api,
  }
}
