export const useUiStore = defineStore("ui", () => {
  const blockScroll = ref(false)

  const toggleScrollBlock = () => {
    blockScroll.value = !blockScroll.value
  }

  const disableScrollBlock = () => {
    blockScroll.value = false
  }

  return {
    blockScroll,
    disableScrollBlock,
    toggleScrollBlock,
  }
})
