import { deserialize } from "@alchemy_cms/json_api"

export default defineNuxtRouteMiddleware(async () => {
  const { api } = useApi()
  const authStore = useAuthStore()
  const rewardStore = useRewardStore()

  const userId = computed(() => authStore.user?.id)

  if (!userId.value) {
    return
  }
  try {
    const response = await api<any>(`/jsonapi/user_details/${userId.value}`)
    rewardStore.setPointsAvailable(deserialize(response))
  } catch (error) {
    console.error(error)
    authStore.resetState()
  }
})
