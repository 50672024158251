export const state = () => ({
  currentStateAbbr: "",
  fragranceData: {},
})

export const getters = {
  currentState(state) {
    return state.fragranceData[state.currentStateAbbr]
  },
}

export const mutations = {
  updateCurrentState(state, newStateAbbr) {
    state.currentStateAbbr = newStateAbbr
  },
  initFragranceData(state, data) {
    state.fragranceData = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
